import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
} from "@reduxjs/toolkit";
import { client } from "../../../utilities/client/client";

const ratesTablesAdapter = createEntityAdapter();

const initialState = ratesTablesAdapter.getInitialState({
  status: "idle",
  error: null,
});

export const fetchZonesRatesTables = createAsyncThunk(
  "shippingZonesRatesTables/fetchZonesRatesTables",
  async (policy_id) => {
    const response = await client.get(
      `${process.env.REACT_APP_API_ENDPOINT}/shipping/policies/${policy_id}/rates-tables/`,
      { credentials: "include" }
    );

    return response.data;
  }
);

export const addRatesTable = createAsyncThunk(
  "shippingZonesRatesTables/addRatesTable",
  async (data) => {
    const body = data;
    const response = await client.post(
      `${process.env.REACT_APP_API_ENDPOINT}/shipping/rates-tables/create/`,
      body,
      {
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      }
    );

    return response.data;
  }
);

export const editRatesTable = createAsyncThunk(
  "shippingZonesRatesTables/editRatesTable",
  async (bData) => {
    const { tableId, data } = bData;
    const body = data;
    const response = await client.update(
      `${process.env.REACT_APP_API_ENDPOINT}/shipping/rates-tables/${tableId}/`,
      body,
      {
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      }
    );

    return response.data;
  }
);

export const deleteRatesTable = createAsyncThunk(
  "shippingZonesRatesTables/deleteRatesTable",
  async (tableId) => {
    const response = await client.delete(
      `${process.env.REACT_APP_API_ENDPOINT}/shipping/rates-tables/${tableId}/`,
      { credentials: "include" }
    );

    return response.data;
  }
);

const shippingZonesRatesTablesSlice = createSlice({
  name: "shippingZonesRatesTables",
  initialState,
  reducers: {
    deletedRatesTable: ratesTablesAdapter.removeOne,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchZonesRatesTables.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchZonesRatesTables.fulfilled, (state, action) => {
        state.status = "succeeded";
        ratesTablesAdapter.addMany(state, action.payload);
      })
      .addCase(fetchZonesRatesTables.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(addRatesTable.pending, (state, action) => {
        state.status = "creating";
      })
      .addCase(addRatesTable.fulfilled, (state, action) => {
        state.status = "succeeded";
        ratesTablesAdapter.addOne(state, action.payload);
      })
      .addCase(addRatesTable.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(editRatesTable.pending, (state, action) => {
        state.status = "updating";
      })
      .addCase(editRatesTable.fulfilled, (state, action) => {
        state.status = "succeeded";
        ratesTablesAdapter.upsertOne(state, action.payload);
      })
      .addCase(editRatesTable.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(deleteRatesTable.pending, (state, action) => {
        state.status = "deleting";
      })
      .addCase(deleteRatesTable.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(deleteRatesTable.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const {
  selectAll: selectAllShippingZonesRatesTables,
  selectById: selectShippingZoneRatesTableById,
  selectIds: selectShippingZonesRatesTablesIds,
} = ratesTablesAdapter.getSelectors((state) => state.shippingZonesRatesTables);

export const selectShippingZoneRatesTable = createSelector(
  [selectAllShippingZonesRatesTables, (state, zoneId) => zoneId],
  (ratesTables, zoneId) =>
    ratesTables.find((table) => table.shipping_zone === zoneId)
);

export const { deletedRatesTable } = shippingZonesRatesTablesSlice.actions;

export default shippingZonesRatesTablesSlice.reducer;
