import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

import store from "./components/app/store";
import { Provider } from "react-redux";

import App from "./components/app/App";
import Home from "./components/app/Home";
const Login = lazy(() => import("./components/authentication/Login"));
const Signup = lazy(() => import("./components/authentication/Signup"));
const EmailConfirmation = lazy(() =>
  import("./components/authentication/EmailConfirmation")
);
const ForgotPassword = lazy(() =>
  import("./components/authentication/ForgotPassword")
);
const ForgotPasswordEmailSent = lazy(() =>
  import("./components/authentication/ForgotPasswordEmailSent")
);
const ResetPassword = lazy(() =>
  import("./components/authentication/ResetPassword")
);
const SellerRegistration = lazy(() =>
  import("./components/authentication/registration/SellerRegistration")
);
const SellersLounge = lazy(() => import("./components/app/SellersLounge"));
const Guides = lazy(() => import("./components/guides/GuidesIndex"));

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<App />}>
            <Route index element={<Home />} />
            <Route
              path="signin"
              element={
                <Suspense fallback={<div>Loading ...</div>}>
                  <Login />
                </Suspense>
              }
            />
            <Route
              path="signup"
              element={
                <Suspense fallback={<div>Loading ...</div>}>
                  <Signup />
                </Suspense>
              }
            />
            <Route
              path="confirm-email/:key/"
              element={
                <Suspense fallback={<div>Loading ...</div>}>
                  <EmailConfirmation />
                </Suspense>
              }
            />
          </Route>
          <Route
            path="seller-registration"
            element={
              <Suspense fallback={<div>Loading ...</div>}>
                <SellerRegistration />
              </Suspense>
            }
          />
          <Route
            path="sellers-lounge/*"
            element={
              <Suspense fallback={<div>Loading ...</div>}>
                <SellersLounge />
              </Suspense>
            }
          />
          <Route
            path="guides/*"
            element={
              <Suspense fallback={<div>Loading ...</div>}>
                <Guides />
              </Suspense>
            }
          />
          <Route
            path="/forgot-password"
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <ForgotPassword />
              </Suspense>
            }
          />
          <Route
            path="/forgot-password/email-sent"
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <ForgotPasswordEmailSent />
              </Suspense>
            }
          />
          <Route
            path="/reset-password/:uid/:token"
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <ResetPassword />
              </Suspense>
            }
          />
        </Routes>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
