import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
} from "@reduxjs/toolkit";
import { client } from "../../../utilities/client/client";

const shippingZonesAdapter = createEntityAdapter();

const initialState = shippingZonesAdapter.getInitialState({
  status: "idle",
  error: null,
});

export const fetchShippingZones = createAsyncThunk(
  "shippingZones/fetchShippingZones",
  async () => {
    const response = await client.get(
      `${process.env.REACT_APP_API_ENDPOINT}/shipping/zones/`,
      {
        headers: {
          Accept: "application/json",
        },
        credentials: "include",
      }
    );

    return response.data;
  }
);

export const addShippingZones = createAsyncThunk(
  "shippingZones/addShippingZones",
  async (bData) => {
    const { policyId, bZones } = bData;
    const body = bZones;
    const response = await client.post(
      `${process.env.REACT_APP_API_ENDPOINT}/shipping/policies/${policyId}/zones/add/`,
      body,
      {
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      }
    );

    return response.data;
  }
);

export const removeShippingZones = createAsyncThunk(
  "shippingZones/removeShippingZones",
  async (bData) => {
    const { policyId, zonesIds } = bData;
    console.log(zonesIds);
    const body = zonesIds;
    const response = await client.update(
      `${process.env.REACT_APP_API_ENDPOINT}/shipping/policies/${policyId}/zones/remove/`,
      body,
      {
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      }
    );

    return response.data;
  }
);

const shippingZonesSlice = createSlice({
  name: "shippingZones",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchShippingZones.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchShippingZones.fulfilled, (state, action) => {
        state.status = "succeeded";
        shippingZonesAdapter.addMany(state, action.payload);
      })
      .addCase(fetchShippingZones.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(addShippingZones.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(addShippingZones.fulfilled, (state, action) => {
        state.status = "succeeded";
        shippingZonesAdapter.upsertMany(state, action.payload);
      })
      .addCase(addShippingZones.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(removeShippingZones.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(removeShippingZones.fulfilled, (state, action) => {
        state.status = "succeeded";
        shippingZonesAdapter.upsertMany(state, action.payload);
      })
      .addCase(removeShippingZones.rejected, (state, action) => {
        state.status = "faield";
        state.error = action.error.message;
      });
  },
});

export const {
  selectAll: selectAllShippingZones,
  selectById: selectShippingZoneById,
  selectIds: selectShippingZonesIds,
} = shippingZonesAdapter.getSelectors((state) => state.shippingZones);

export const selectShippingRegionZones = createSelector(
  [selectAllShippingZones, (state, regionId) => regionId],
  (shippingZones, regionId) =>
    shippingZones.filter((zone) => zone.zone_region === regionId)
);

export const selectShippingRegionSelectedZonesIds = createSelector(
  [selectAllShippingZones, (state, args) => args],
  (shippingZones, args) => {
    const { regionId, policyId } = args;
    const requiredShippingZones = shippingZones.filter(
      (zone) => zone.zone_region === parseInt(regionId)
    );

    const policyZones = requiredShippingZones.filter((zone) =>
      zone.policies.includes(parseInt(policyId))
    );
    const shippingRegionZones = policyZones.map((zone) => zone.id);

    return shippingRegionZones;
  }
);

export const selectShippingRegionUnassociatedZones = createSelector(
  [selectAllShippingZones, (state, args) => args],
  (shippingZones, args) => {
    const { regionId, policyId } = args;
    const regionZones = shippingZones.filter(
      (zone) => zone.zone_region === regionId
    );
    const requiredZones = regionZones.filter(
      (zone) => !zone.policies.includes(policyId)
    );

    return requiredZones;
  }
);

export default shippingZonesSlice.reducer;
