import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from "@reduxjs/toolkit";
import { client } from "../../../utilities/client/client";

const initialState = {
  status: "idle",
  shippingPolicy: null,
  error: null,
};

export const fetchShippingPolicy = createAsyncThunk(
  "shippingPolicy/fetchShippingPolicy",
  async (policy_id) => {
    const response = await client.get(
      `${process.env.REACT_APP_API_ENDPOINT}/shipping-policies/${policy_id}/`,
      {
        headers: {
          Accept: "application/json",
        },
        credentials: "include",
      }
    );

    return response.data;
  }
);

const shippingPolicySlice = createSlice({
  name: "shippingPolicy",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchShippingPolicy.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchShippingPolicy.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.shippingPolicy = action.payload;
      })
      .addCase(fetchShippingPolicy.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.messsage;
      });
  },
});

export const selectShippingPolicy = createSelector(
  (state) => state.shippingPolicy,
  (shippingPolicy) => shippingPolicy.shippingPolicy
);

export default shippingPolicySlice.reducer;
