import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
} from "@reduxjs/toolkit";
import { client } from "../../utilities/client/client";

const promotionsAdapter = createEntityAdapter();

const initialState = promotionsAdapter.getInitialState({
  fetchStatus: "idle",
  createStatus: "idle",
  updateStatus: "idle",
  deleteStatus: "idle",
  addSneakersStatus: "idle",
  removeSneakerStatus: "idle",
  changeSneakerPromotionStatus: "idle",
  fetchError: null,
  createError: null,
  updateError: null,
  deleteError: null,
  addSneakersError: null,
  removeSneakerError: null,
  changeSneakerPromotionError: null,
});

export const fetchPromotions = createAsyncThunk(
  "promotions/fetchPromotions",
  async () => {
    const response = await client.get(
      `${process.env.REACT_APP_API_ENDPOINT}/promotions/vendor/`,
      { credentials: "include" }
    );
    return response.data;
  }
);

export const fetchPromotion = createAsyncThunk(
  "promotions/fetchPromotion",
  async (promotionId) => {
    const response = await client.get(
      `${process.env.REACT_APP_API_ENDPOINT}/promotions/vendor/${promotionId}/`,
      { credentials: "include" }
    );

    return response.data;
  }
);

export const createPromotion = createAsyncThunk(
  "promotions/createPromotion",
  async (promotionData) => {
    const body = promotionData;
    const response = await client.post(
      `${process.env.REACT_APP_API_ENDPOINT}/promotions/vendor/`,
      body,
      {
        headers: { "Content-Type": "application/json" },
        credentials: "include",
      }
    );

    return response.data;
  }
);

export const updatePromotion = createAsyncThunk(
  "promotions/updatePromotion",
  async ({ promotionId, promotionData }) => {
    const body = promotionData;
    const response = await client.update(
      `${process.env.REACT_APP_API_ENDPOINT}/promotions/vendor/${promotionId}/`,
      body,
      {
        headers: { "Content-Type": "application/json" },
        credentials: "include",
      }
    );

    return response.data;
  }
);

export const addSneakersToPromotion = createAsyncThunk(
  "promotions/addSneakersToPromotion",
  async ({ promotionId, selectedSneakersIds }) => {
    const body = {
      selected_sneakers_ids: selectedSneakersIds,
    };
    const response = await client.patch(
      `${process.env.REACT_APP_API_ENDPOINT}/promotions/vendor/${promotionId}/add-sneakers/`,
      body,
      {
        headers: { "Content-Type": "application/json" },
        credentials: "include",
      }
    );

    return response.data;
  }
);

export const removeSneakerFromPromotion = createAsyncThunk(
  "promotions/removeSneakerFromPromotion",
  async ({ promotionId, sneakerId }) => {
    const body = {
      sneaker_id: sneakerId,
    };
    const response = await client.patch(
      `${process.env.REACT_APP_API_ENDPOINT}/promotions/vendor/${promotionId}/remove-sneaker/`,
      body,
      {
        headers: { "Content-Type": "application/json" },
        credentials: "include",
      }
    );

    return response.data;
  }
);

export const changeSneakerToDiffPromotion = createAsyncThunk(
  "promotions/changeSneakerToDiffPromotion",
  async ({ promotionId, sneakerId }) => {
    const body = {
      new_promotion_id: promotionId,
      sneaker_id: sneakerId,
    };

    const response = await client.post(
      `${process.env.REACT_APP_API_ENDPOINT}/promotions/vendor/change-sneaker-promotion/`,
      body,
      {
        headers: { "Content-Type": "application/json" },
        credentials: "include",
      }
    );

    return response.data;
  }
);

const promotionsSlice = createSlice({
  name: "promotions",
  initialState,
  reducers: {
    deletedPromotion: promotionsAdapter.removeOne,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPromotions.pending, (state, action) => {
        state.fetchStatus = "loading";
      })
      .addCase(fetchPromotions.fulfilled, (state, action) => {
        state.fetchStatus = "success";
        promotionsAdapter.addMany(state, action.payload);
      })
      .addCase(fetchPromotions.rejected, (state, action) => {
        state.fetchStatus = "failed";
        state.fetchError = action.error.message;
      })
      .addCase(fetchPromotion.fulfilled, promotionsAdapter.upsertOne)
      .addCase(createPromotion.pending, (state, action) => {
        state.createStatus = "loading";
      })
      .addCase(createPromotion.fulfilled, (state, action) => {
        state.createStatus = "success";
        promotionsAdapter.upsertOne(state, action.payload);
      })
      .addCase(createPromotion.rejected, (state, action) => {
        state.createStatus = "failed";
        state.createError = action.error.message;
      })
      .addCase(updatePromotion.pending, (state, action) => {
        state.updateStatus = "loading";
      })
      .addCase(updatePromotion.fulfilled, (state, action) => {
        state.updateStatus = "success";
        promotionsAdapter.upsertOne(state, action.payload);
      })
      .addCase(updatePromotion.rejected, (state, action) => {
        state.updateStatus = "failed";
        state.updateError = action.error.message;
      })
      .addCase(addSneakersToPromotion.pending, (state, action) => {
        state.addSneakersStatus = "loading";
      })
      .addCase(addSneakersToPromotion.fulfilled, (state, action) => {
        state.addSneakersStatus = "success";
        promotionsAdapter.upsertOne(state, action.payload);
      })
      .addCase(addSneakersToPromotion.rejected, (state, action) => {
        state.addSneakersStatus = "failed";
        state.addSneakersError = action.error.message;
      })
      .addCase(removeSneakerFromPromotion.pending, (state, action) => {
        state.removeSneakerStatus = "loading";
      })
      .addCase(removeSneakerFromPromotion.fulfilled, (state, action) => {
        state.removeSneakerStatus = "success";
        promotionsAdapter.upsertOne(state, action.payload);
      })
      .addCase(removeSneakerFromPromotion.rejected, (state, action) => {
        state.removeSneakerStatus = "failed";
        state.removeSneakerError = action.error.message;
      })
      .addCase(changeSneakerToDiffPromotion.pending, (state, action) => {
        state.changeSneakerPromotionStatus = "loading";
      })
      .addCase(changeSneakerToDiffPromotion.fulfilled, (state, action) => {
        state.changeSneakerPromotionStatus = "success";
        promotionsAdapter.upsertOne(state, action.payload);
      })
      .addCase(changeSneakerToDiffPromotion.rejected, (state, action) => {
        state.changeSneakerPromotionStatus = "failed";
        state.changeSneakerPromotionError = action.error.message;
      });
  },
});

export const {
  selectAll: selectAllPromotions,
  selectById: selectPromotionById,
  selectIds: selectPromotionsIds,
} = promotionsAdapter.getSelectors((state) => state.promotions);

// create selectors
export const selectPromotionsNotRunningSneaker = createSelector(
  [selectAllPromotions, (state, sneakerId) => sneakerId],
  (promotions, sneakerId) => {
    // get all promotions not running sneaker
    const promotionsExcemptSneaker = promotions.filter(
      (promotion) => !promotion.associated_sneakers.includes(sneakerId)
    );

    return promotionsExcemptSneaker;
  }
);

// export actions

export const { deletedPromotion } = promotionsSlice.actions;

export default promotionsSlice.reducer;
