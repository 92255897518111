import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from "@reduxjs/toolkit";
import { client } from "../../utilities/client/client";

const initialState = {
  status: "idle",
  store: null,
  error: null,
};

// the async thunk to pull data from db about store
export const fetchStore = createAsyncThunk("store/fetchStore", async () => {
  const response = await client.get(
    `${process.env.REACT_APP_API_ENDPOINT}/store/`,
    { credentials: "include" }
  );
  return response.data;
});

export const updateStore = createAsyncThunk(
  "store/updateStore",
  async ({
    slug,
    storeName,
    storeMission,
  }) => {
    console.log(storeName);
    const body = {
      store_name: storeName,
      mission: storeMission,
    };
    const response = await client.update(
      `${process.env.REACT_APP_API_ENDPOINT}/stores/${slug}/`,
      body,
      {
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      }
    );

    return response.data;
  }
);

const storeSlice = createSlice({
  name: "store",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchStore.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchStore.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.store = action.payload;
      })
      .addCase(fetchStore.rejected, (state, action) => {
        state.status = "failded";
        state.error = action.error.message;
      })
      .addCase(updateStore.pending, (state, action) => {
        state.status = "updating";
      })
      .addCase(updateStore.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.store = action.payload;
      })
      .addCase(updateStore.rejected, (state, action) => {
        state.status = "failed update";
        state.error = action.error.message;
      });
  },
});

export const selectStore = createSelector(
  (state) => state.store,
  (store) => store.store
);

export default storeSlice.reducer;
