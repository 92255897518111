import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
} from "@reduxjs/toolkit";
import { client } from "../../../utilities/client/client";

const freeShippingMethodsAdapter = createEntityAdapter();

const initialState = freeShippingMethodsAdapter.getInitialState({
  status: "idle",
  error: null,
});

export const fetchZonesFreeShippingMethods = createAsyncThunk(
  "shippingZonesFreeShippingMethods/fetchZonesFreeShippingMethods",
  async (policy_id) => {
    const response = await client.get(
      `${process.env.REACT_APP_API_ENDPOINT}/shipping/policies/${policy_id}/free-shipping-methods`,
      { credentials: "include" }
    );

    return response.data;
  }
);

export const addFreeShipping = createAsyncThunk(
  "shippingZonesFreeShippingMethods/addFreeShipping",
  async (data) => {
    const body = data;
    const response = await client.post(
      `${process.env.REACT_APP_API_ENDPOINT}/shipping/free-shipping-methods/create/`,
      body,
      {
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      }
    );

    return response.data;
  }
);

export const editFreeShipping = createAsyncThunk(
  "shippingZonesFreeShippingMethods/editFreeShipping",
  async (bData) => {
    const { methodId, data } = bData;
    const body = data;
    const response = await client.update(
      `${process.env.REACT_APP_API_ENDPOINT}/shipping/free-shipping-methods/${methodId}/`,
      body,
      {
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      }
    );

    return response.data;
  }
);

export const deleteFreeShipping = createAsyncThunk(
  "shippingZonesFreeShippingMethods/deleteFreeShipping",
  async (methodId) => {
    const response = await client.delete(
      `${process.env.REACT_APP_API_ENDPOINT}/shipping/free-shipping-methods/${methodId}/`,
      { credentials: "include" }
    );

    return response.data;
  }
);

const shippingZonesFreeShippingMethodsSlice = createSlice({
  name: "shippingZonesFreeShippingMethods",
  initialState,
  reducers: {
    deletedFreeShipping: freeShippingMethodsAdapter.removeOne,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchZonesFreeShippingMethods.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchZonesFreeShippingMethods.fulfilled, (state, action) => {
        state.status = "succeeded";
        freeShippingMethodsAdapter.addMany(state, action.payload);
      })
      .addCase(fetchZonesFreeShippingMethods.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(addFreeShipping.pending, (state, action) => {
        state.status = "creating";
      })
      .addCase(addFreeShipping.fulfilled, (state, action) => {
        state.status = "succeeded";
        freeShippingMethodsAdapter.addOne(state, action.payload);
      })
      .addCase(addFreeShipping.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(editFreeShipping.pending, (state, action) => {
        state.status = "editing";
      })
      .addCase(editFreeShipping.fulfilled, (state, action) => {
        state.status = "succeeded";
        freeShippingMethodsAdapter.upsertOne(state, action.payload);
      })
      .addCase(editFreeShipping.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(deleteFreeShipping.pending, (state, action) => {
        state.status = "deleting";
      })
      .addCase(deleteFreeShipping.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(deleteFreeShipping.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const {
  selectAll: selectAllShippingZonesFreeShippingMethods,
  selectById: selectShippingZoneFreeShippingMethodById,
  selectIds: selectShippingZonesFreeShippingMethodsIds,
} = freeShippingMethodsAdapter.getSelectors(
  (state) => state.shippingZonesFreeShippingMethods
);

export const selectZoneFreeShippingMethod = createSelector(
  [selectAllShippingZonesFreeShippingMethods, (state, zoneId) => zoneId],
  (freeShippingMethods, zoneId) =>
    freeShippingMethods.find((method) => method.shipping_zone === zoneId)
);

// export the actions
export const { deletedFreeShipping } =
  shippingZonesFreeShippingMethodsSlice.actions;

export default shippingZonesFreeShippingMethodsSlice.reducer;
