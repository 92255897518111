import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import MainNavbar from "./MainNavbar";
import Footer from "./Footer";

import welcomeImage from "../../assets/undraw_product_iteration_kjok.svg";
import addPost from "../../assets/undraw_add_post_re_174w.svg";
import logistics from "../../assets/undraw_logistics_x-4-dc.svg";
import performaceImage from "../../assets/undraw_all_the_data_re_hh4w.svg";
import reviewsImage from "../../assets/undraw_reviews_lp8w.svg";

const useStyles = makeStyles((theme) => ({
  welcome: {
    paddingTop: "4rem",
    minHeight: "75vh",
    [theme.breakpoints.up("sm")]: {
      paddingTop: "5rem",
    },
    [theme.breakpoints.up("xl")]: {
      paddingTop: "5rem",
    },
  },
  valuePropositions: {
    minHeight: 600,
  },
  valuePropositionsHeader: {
    minHeight: 200,
  },
  primaryBackground: {
    backgroundColor: "rgba(250, 202, 43, 0.1)",
  },
  alternateBackground: {
    backgroundColor: "rgba(244, 103, 91, 0.02)",
  },
}));

const Home = () => {
  const classes = useStyles();

  return (
    <>
      <MainNavbar />
      <main>
        <div className={`container-fluid px-0 ${classes.welcome}`}>
          <div className="container-xl h-100">
            <div className="row gx-5 gy-3 pt-2 pt-lg-5 pb-5 align-items-center">
              <div className="col-md-6">
                <h5 className="fw-bold text-uppercase mb-4 d-none d-md-block">
                  Grow your sneaker business with us
                </h5>
                <h1 className="display-2 text-start fw-bold text-primary">
                  Sell sneakers, Manage inventory, Track store performance, all
                  from Within
                </h1>
                <Link to={"signup"} className="btn btn-primary btn-lg mt-3">
                  Get Started
                </Link>
              </div>
              <div className="col-md-6">
                <img src={welcomeImage} className="img-fluid" alt="welcome" />
              </div>
            </div>
          </div>
          <div className={classes.valuePropositionsHeader}>
            <h5 className="text-center display-5 mb-5 align-items-center">
              What We have to Offer
            </h5>
          </div>
          <div
            className={`container-fluid ${classes.alternateBackground} ${classes.valuePropositions}`}
          >
            <div className="container-xl h-100 pt-5">
              <div className="row gx-5 gy-3 justify-content-between">
                <div className="col-md-6">
                  <img src={addPost} className="img-fluid" alt="add" />
                </div>
                <div className="col-md-5">
                  <h5 className="fw-bold display-6 mb-4">Easy product creation</h5>
                  <p>
                    We bring you an easy to follow process for product creation.
                  </p>
                  <p>
                    Products that are sold on{" "}
                    <span className="text-primary">Kicstoms</span> are created
                    by the Sellers. With Our platform, you add the Products you
                    want to sell on our platform, set your price, create your
                    own promotions to run on those Products and more.
                  </p>
                  <p>
                    On <span className="text-primary">Kicstoms</span> you are in
                    control of your products
                  </p>
                  <Link
                    className="btn btn-primary"
                    to={"/guides/sneaker-images-specifications"}
                  >
                    Read Product Images Specifications
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className={`container-fluid ${classes.valuePropositions}`}>
            <div className="container-xl h-100 pt-5">
              <div className="row gx-5 gy-3 justify-content-between">
                <div className="col-md-5">
                  <h5 className="fw-bold display-6 mb-4">Manage Your Stock</h5>
                  <p>
                    We provide you with a system for Inventory Management. Some
                    of the integrated Inventory Management Techniques include{" "}
                    <span className="text-primary">
                      Reorder Point Formula, ABC Classification
                    </span>{" "}
                    and more. You will be able to ensure smooth running of your
                    business at optimal conditions.
                  </p>
                </div>
                <div className="col-md-6">
                  <img
                    src={logistics}
                    className="img-fluid"
                    alt="stock management"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            className={`container-fluid ${classes.primaryBackground} ${classes.valuePropositions}`}
          >
            <div className="container-xl h-100 pt-5">
              <div className="row gx-5 gy-3 justify-content-between">
                <div className="col-md-6">
                  <img
                    src={performaceImage}
                    className="img-fluid"
                    alt="performance"
                  />
                </div>
                <div className="col-md-5">
                  <h5 className="fw-bold display-6 mb-4">Track Your Store Performance</h5>
                  <p>
                    <strong>
                      Would you be a business owner if you don't track your
                      store's performance?
                    </strong>{" "}
                    We don't think so as well. It is for this reason with
                    provide you with analytics for your store and how your
                    business is doing.
                  </p>
                  <p>
                    You will be able to keep track your store's{" "}
                    <span className="text-primary">
                      Revenue, customers gained, Sneakers sold, Best Selling
                      sneakers
                    </span>{" "}
                    and more.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className={`container-fluid ${classes.valuePropositions}`}>
            <div className="container-xl h-100 pt-5">
              <div className="row gx-5 gy-3 justify-content-between">
                <div className="col-md-5">
                  <h5 className="fw-bold display-6 mb-4">Keep in touch with your customers</h5>
                  <p>
                    Customers leave reviews of sneakers they have bought, or
                    inquiries to your store. We provide you with means of
                    responding to them from within the platform... Always keep
                    them happy!!!
                  </p>
                </div>
                <div className="col-md-6">
                  <img src={reviewsImage} className="img-fluid" alt="reviews" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="text-center py-5">
          <h5 className="fw-bold display-5">JOIN US NOW</h5>
          <Link to={"/signup"} className="btn btn-primary">
            Continue to Signup
          </Link>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default Home;
