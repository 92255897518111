import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import { client } from "../../utilities/client/client";

// adapter
const storeOrderItemsAdapter = createEntityAdapter();

// initial state
const initialState = storeOrderItemsAdapter.getInitialState({
  status: "idle",
  error: null,
});

// async thunks
const fetchStoreOrderItems = createAsyncThunk(
  "storeOrderItems/fetchStoreOrderItems",
  async (orderId) => {
    const response = await client.get(
      `${process.env.REACT_APP_API_ENDPOINT}/store-orders/online/${orderId}/order-items/`,
      { credentials: "include" }
    );

    return response.data;
  }
);

// the slice
const storeOrderItemsSlice = createSlice({
  name: "storeOrderItems",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchStoreOrderItems.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchStoreOrderItems.fulfilled, (state, action) => {
        state.status = "succeeded";
        storeOrderItemsAdapter.addMany(state, action.payload);
      })
      .addCase(fetchStoreOrderItems.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

// actions and selectors
export const {
  selectAll: selectAllStoreOrderItems,
  selectIds: selectStoreOrderItemsIds,
  selectId: selectStoreOrderById,
} = storeOrderItemsSlice.actions;

// export reducer
export default storeOrderItemsSlice.reducer;
