import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
} from "@reduxjs/toolkit";
import { client } from "../../../utilities/client/client";

const shippingRegionsAdapter = createEntityAdapter();

const initialState = shippingRegionsAdapter.getInitialState({
  status: "idle",
  error: null,
});

export const fetchShippingRegions = createAsyncThunk(
  "shippingRegions/fetchShippingRegions",
  async () => {
    const response = await client.get(
      `${process.env.REACT_APP_API_ENDPOINT}/shipping/regions/`,
      {
        headers: {
          Accept: "application/json",
        },
        credentials: "include",
      }
    );

    return response.data;
  }
);

export const addShippingRegions = createAsyncThunk(
  "shippingRegions/addShippingRegions",
  async (bData) => {
    const { policyId, bRegions } = bData;
    const body = bRegions;
    const response = await client.post(
      `${process.env.REACT_APP_API_ENDPOINT}/shipping/policies/${policyId}/regions/add/`,
      body,
      {
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      }
    );

    return response.data;
  }
);

export const removeShippingRegion = createAsyncThunk(
  "shippingRegions/removeShippingRegion",
  async (bData) => {
    const { policyId, regionId } = bData;
    const body = {};
    const response = await client.update(
      `${process.env.REACT_APP_API_ENDPOINT}/shipping/policies/${policyId}/regions/${regionId}/remove/`,
      body,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        credentials: "include",
      }
    );

    return response.data;
  }
);

const shippingRegionsSlice = createSlice({
  name: "shippingRegions",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchShippingRegions.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchShippingRegions.fulfilled, (state, action) => {
        state.status = "succeeded";
        shippingRegionsAdapter.addMany(state, action.payload);
      })
      .addCase(fetchShippingRegions.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(addShippingRegions.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(addShippingRegions.fulfilled, (state, action) => {
        state.status = "succeeded";
        shippingRegionsAdapter.upsertMany(state, action.payload);
      })
      .addCase(addShippingRegions.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(removeShippingRegion.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(removeShippingRegion.fulfilled, (state, action) => {
        state.status = "succeeded";
        shippingRegionsAdapter.upsertOne(state, action.payload);
      })
      .addCase(removeShippingRegion.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const {
  selectAll: selectAllShippingRegions,
  selectById: selectShippingRegionById,
  selectIds: selectShippingRegionsIds,
} = shippingRegionsAdapter.getSelectors((state) => state.shippingRegions);

export const selectShippingPolicyRegionsIds = createSelector(
  [selectAllShippingRegions, (state, policyId) => policyId],
  (shippingRegions, policyId) => {
    const selectedShippingRegions = shippingRegions.filter((region) =>
      region.policies.some((id) => id === policyId)
    );
    const shippingPolicyRegionsIds = selectedShippingRegions.map(
      (region) => region.id
    );

    return shippingPolicyRegionsIds;
  }
);

export const selectShippingPolicyUnAssociatedShippingRegionsIds =
  createSelector(
    [selectAllShippingRegions, (state, policyId) => policyId],
    (shippingRegions, policyId) => {
      const unAssociatedShippingRegions = shippingRegions.filter(
        (region) => !region.policies.some((id) => id === parseInt(policyId))
      );

      const unAssociatedShippingRegionsIds = unAssociatedShippingRegions.map(
        (region) => region.id
      );

      return unAssociatedShippingRegionsIds;
    }
  );

export default shippingRegionsSlice.reducer;
